import { Component, Injector } from '@angular/core';
import { SharedModule } from "../../shared.module";
import { BaseComponent } from '@src/app/core/base/base.component';

@Component({
  selector: 'app-new-product-photos-input',
  templateUrl: './new-product-photos-input.component.html',
  styleUrls: ['./new-product-photos-input.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class NewProductPhotosInputComponent extends BaseComponent{
  photoLimit = 6;
  photosToSave: {isUpload:boolean, file: File, url?:string}[] = [];
  photosToDelete: number[] = [];
  photosToDisplay: { name: string; data: string }[] = [];
  remotePhotosToDisplay: { id: number; path: string; thumbnailPath: string; }[] = [];

  constructor(private readonly injector: Injector) {
    super(injector);
  }

  updatePhotosToDisplay($event: any) {
    if ($event.target.files.length === 0) {
      return;
    }
  
    const allowedFormats = ['image/png', 'image/jpg', 'image/jpeg'];
  
    // Validate file types for all selected images
    for (let i = 0; i < $event.target.files.length; i++) {
      const file = $event.target.files[i];
      if (!allowedFormats.includes(file.type)) {
        this.httpService.showMessage(
          this.translate.instant('MSGS.GENERAL.INVALID_IMAGE'),
          'error'
        );
        return;
      }
    }
  
    // Calculate the total number of photos (including selected and already uploaded ones)
    let currentPhotosNumber = this.photosToSave.length + this.remotePhotosToDisplay.length;
    
    // Check if the total number of photos exceeds the photo limit
    if (currentPhotosNumber + $event.target.files.length > this.photoLimit) {
      this.httpService.showMessage(
        this.translate.instant('MSGS.GENERAL.INVALID_UPLOADING_IMAGE'),
        'error'
      );
      return;
    }
  
    // Calculate remaining slots for photos
    let remainingPhotoNumber = this.photoLimit - currentPhotosNumber;
  
    // Loop through the selected files and add them to the photosToSave and photosToDisplay arrays
    for (let index = 0; index < $event.target.files.length; index++) {
      if (index < remainingPhotoNumber) {
        this.photosToSave.push({ isUpload: false, file: $event.target.files[index] });
        this.photosToDisplay.push({
          name: $event.target.files[index].name,
          data: URL.createObjectURL($event.target.files[index]),
        });
      }
    }
  
    // If more files are selected than the remaining slots, only add the allowed number of files
    if ($event.target.files.length > remainingPhotoNumber) {
      this.httpService.showMessage(
        this.translate.instant('MSGS.GENERAL.INVALID_UPLOADING_IMAGE'),
        'error'
      );
    }
  }


  removePhoto(index: number) {
    this.photosToSave.splice(index, 1);
    this.photosToDisplay.splice(index, 1);
  }

  removeRemotePhoto(id: number) {
    this.photosToDelete.push(id);
    this.remotePhotosToDisplay = this.remotePhotosToDisplay.filter(
      (photo) => photo.id !== id,
    );
  }

  removeAllPhotos() {
    this.photosToSave = [];
    this.photosToDisplay = [];
  }
}
