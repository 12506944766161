<div class="mb-4 px-4 pt-4 pb-5 bg-white br-8">
  <div class="mb-4 align-items-center d-flex justify-content-between flex-row-reverse">
    <div class="d-flex gap-3" *ngIf="pageMode !== 'add'" [viewPermission]="[this.permissionsList.UPDATE_USERS]">
      <div class="d-inline-block" ngbDropdown>
        <button [disabled]="isSuperAdmin() === true" [class.disabled]="isSuperAdmin() === true"
          class="dropdown-toggle btn-dropdown btn-140 btn-outline btn-outline-primary ms-2 mb-0"
          [ngClass]="getClass()" id="dropdownStatus" ngbDropdownToggle
          [disabled]="isSuperAdmin() || baseModel?.status === 'unverified'"
           [class.disabled]="isSuperAdmin() || baseModel?.status === 'unverified' "

          >
          @switch (baseModel.status) {
          @case ('active') {
          <i class="icon middle me-2 icon-tick-circle"></i>
          }
          @case ('inactive') {
          <i class="icon middle me-2 icon-close-circle"></i>
          }
          @default {
          <i class="icon middle me-2 icon-minus-cirlce"></i>
          }
          }
          {{ 'COMMON.TEXT.' + status[baseModel?.status]?.toUpperCase() | translate }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownStatus">
          <button ngbDropdownItem (click)="updateUserStatus('active', baseModel.id)"
            [disabled]="baseModel.status === 'active'">
            <i class="icon middle me-2 icon-tick-circle" [ngClass]="{'text-muted': baseModel.status === 'active'}"></i>
            {{ 'GENERAL.BUTTONS.ACTIVATE' | translate }}
          </button>
          <button ngbDropdownItem (click)="updateUserStatus('inactive', baseModel.id)"
            [disabled]="baseModel.status === 'inactive'">
            <i class="icon middle me-2 icon-close-circle"
              [ngClass]="{'text-muted': baseModel.status === 'inactive'}"></i>
            {{ 'GENERAL.BUTTONS.DEACTIVATE' | translate }}
          </button>
        </div>
      </div>

      <button *ngIf="!isSuperAdmin()" (click)="deleteUser()" type="button"
        class="btn btn-outline btn-outline-primary btn-140 btn-icon-text">
        <i class="icon icon-trash-filled middle"></i>
        {{ 'GENERAL.BUTTONS.DELETE' | translate }}
      </button>
    </div>
  </div>

  <app-user-info></app-user-info>
</div>

<!-- Form Validation Handling Errors -->
<ng-template #reqErrRef let-control="control" let-name="name">
  @if ((control?.touched || control?.dirty) && control?.invalid) {
  @if (control?.hasError('required')) {
  <small class="text-danger">{{ 'COMMON.VALIDATION.REQUIRED' | translate }}</small>
  } @else if (control?.hasError('pattern')) {
  <small class="text-danger">{{ 'COMMON.VALIDATION.INVALID_FORMAT' | translate }}</small>
  }
  }
</ng-template>

<app-confirm-dialog [confirmText]="dialogConfirmText" [closeText]="'GENERAL.BUTTONS.CLOSE' | translate"
  [message]="dialogMessage" [extraIcon]="extraIcon" [iconClass]="iconClass" [eventType]="confirmEventAction"
  (closeDialog)="processModalClose($event)"></app-confirm-dialog>
