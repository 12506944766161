import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localize',
  standalone: true,
})
export class LocalizePipe implements PipeTransform {
  /**
   * Transforms the given value based on the current language setting in localStorage.
   *
   * @param {any} value - The value to be transformed.
   * @param {any} [args] - Optional arguments for the transformation.
   * @return {any} The transformed value based on the current language setting.
   */
  transform(value: any, args?: any): any {
    const languageConfig = localStorage.getItem('sid-app-config');
    const language = languageConfig ? JSON.parse(languageConfig).language : 'fr';
    const localizedValue = value[language?.code || language];

    if (localizedValue === undefined || localizedValue === null) {
      return value;
    }

    return localizedValue === '' ? '-' : localizedValue;
  }
}
