<div class="filters">
  <div class="filters-header d-flex justify-content-between align-items-center pt-2 pb-2 px-3">
    <div class="d-flex justify-content-start align-items-center flex-grow-1">
      @if (!hideFilter) {
        <button
          class="chip chip-label-icon chip-label-icon-outline icon-group chip-120 accordion-header mb-0"
          id="accordion-heading"
          (click)="isOpenFilter = !isOpenFilter">
          <i class="icon middle icon-filter-search icon-before"></i>
          {{ 'GENERAL.BUTTONS.FILTERS' | translate }}
          <i
            class="icon icon-after"
            [ngClass]="isOpenFilter ? 'icon-arrow-up' : 'icon-arrow-down'"></i>
        </button>
      }
      <div class="d-inline-block" ngbDropdown *ngIf="enableTableAction">
        <button
          class="chip chip-label-icon chip-label-icon-outline icon-group chip-120 ms-2 mb-0"
          id="dropdownActions"
          ngbDropdownToggle>
          <i class="icon middle icon-setting"></i>
          {{ 'GENERAL.BUTTONS.ACTIONS' | translate }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownActions">
          @for (action of tableActions; track action.key) {
            <button (click)="onEmitAction(action)" ngbDropdownItem>
              <i [class]="'icon middle me-1 icon-' + action.icon"></i>
              {{
                'GENERAL.BUTTONS.' + action.actionName.toUpperCase().replaceAll(' ', '_')
                  | translate
              }}
            </button>
          }
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-center flex-grow-1">
      <ng-container *ngIf="searchable">
        <app-search-bar
          [placeholder]="'COMMON.TEXT.SEARCH' | translate"
          (searchClick)="onSearchBarClick($event)"></app-search-bar>
      </ng-container>
      <ng-container *ngIf="showZoneButton">
        <button
          type="button"
          class="btn btn-outline btn-outline-primary ms-2 me-2 btn-140"
          (click)="getBtnClickType('zones')">
          {{ 'GENERAL.BUTTONS.VIEW_ALL_ZONES' | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="showRefreshButton">
        <button
          type="button"
          class="btn btn-primary btn-min ms-2 btn-140"
          (click)="buttonClick.emit('refresh')">
          <ng-container *ngIf="linkIcon"></ng-container>
          {{ 'GENERAL.BUTTONS.REFRESH' | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="link || showCreateButton">
        <ng-container *ngIf="showCreateButton; else defaultLinkRef">
          <button
            type="button"
            class="btn btn-primary btn-min ms-2 btn-140"
            (click)="buttonClick.emit('create')">
            <ng-container *ngIf="linkIcon">
              <i [ngClass]="'icon icon-add-square ' + linkIcon" class="middle px-1 me-2"></i>
            </ng-container>
            {{ linkText }}
          </button>
        </ng-container>
        <ng-template #defaultLinkRef>
          <a
            class="btn btn-primary btn-icon-text btn-140 ms-2"
            [routerLink]="link"
            [queryParams]="queryParams">
            <ng-container *ngIf="linkIcon">
              <i [ngClass]="'icon icon-add-square ' + linkIcon" class="middle px-1 me-2"></i>
            </ng-container>
            {{ linkText }}
          </a>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="showAddDropDown">
        <div class="filter-dropdown-btn" ngbDropdown>
          <a
            class="dropdown-toggle btn-140 btn-dropdown btn-primary ms-2"
            ngbDropdownToggle
            id="addDropdown"
            role="button">
            <ng-container *ngIf="linkIcon">
              <i [ngClass]="'icon icon-add-square ' + linkIcon" class="middle px-1 me-2"></i>
            </ng-container>
            <span class="fw-bold ms-1 me-1 d-none d-md-inline-block">{{ linkText }}</span>
          </a>
          <div ngbDropdownMenu aria-labelledby="addDropdown">
            <a
              *ngFor="let menu of addDropDownData"
              ngbDropdownItem
              class="py-2"
              [routerLink]="menu?.link"
              [queryParams]="menu?.param">
              <span class="ms-1">{{ menu?.name || '' | translate }}</span>
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="!hideFilter">
    <ng-container *ngIf="filters.length > 0 && isOpenFilter">
      <div class="accordion-body py-2 px-3">
        <hr class="mt-0" />
        <form name="filters" [formGroup]="formGroup" novalidate>
          <div
            class="justify-content-between gap-5"
            [ngClass]="[isBtnBelow ? 'd-block' : 'd-flex']">
            <div class="d-flex flex-fill align-items-center gap-2 filter-fields flex-1 flex-wrap">
              <div
                class="d-flex input-container"
                [ngStyle]="{
                  width:
                    filter.type !== 'past' &&
                    filter.type !== 'current' &&
                    filter.type !== 'upcoming' &&
                    filter.type !== 'expired' &&
                    filter.type !== 'yesterday' &&
                    filter.type !== 'today' &&
                    filter.type !== 'sevenDays' &&
                    filter.type !== 'tomorrow'
                      ? ''
                      : 'auto'
                }"
                *ngFor="let filter of filters; index as i">
                <ng-container [ngSwitch]="filter.type">
                  <input
                    *ngSwitchCase="'text'"
                    type="text"
                    class="form-control"
                    name="input-{{ filter.key }}"
                    formControlName="{{ filter.key }}"
                    placeholder="{{ filter.placeholder || '' | translate }}"
                    (input)="onInputChange($any($event.target).value, filter || null, false, i)" />
                  <input
                    *ngSwitchCase="'number'"
                    type="number"
                    class="form-control"
                    name="input-{{ filter.key }}"
                    formControlName="{{ filter.key }}"
                    placeholder="{{ filter.placeholder || '' | translate }}"
                    (input)="onInputChange($any($event.target).value, filter || null, false, i)" />
                  <ng-select
                    class="bg-white w-100 cus-select clear-disable"
                    [searchable]="true"
                    [clearable]="false"
                    *ngSwitchCase="'option'"
                    [closeOnSelect]="filter.multiple ? false : true"
                    [clearSearchOnAdd]="true"
                    formControlName="{{ filter.key }}"
                    bindLabel="label"
                    bindValue="value"
                    [(ngModel)]="filter.selectedValue"
                    [placeholder]="filter.placeholder || '' | translate"
                    [multiple]="filter.multiple || false"
                    (change)="onSelectChange($event, filter, i)">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      @for (item of items | slice: 0 : 1; track item) {
                        <div class="ng-value">
                          <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">
                            ×
                          </span>
                          <span class="ng-value-label">
                            {{ formateThis(item, filter.field) | translate }}
                          </span>
                        </div>
                      }
                      @if (items?.length > 1) {
                        <div class="ng-value" [style.height]="'31.2px'">
                          <span class="ng-value-label">(+{{ items?.length - 1 }})</span>
                        </div>
                      }
                    </ng-template>
                    <ng-option *ngFor="let dp of filter.options" [value]="dp">
                      {{
                        sharedDataService.currentLanguage === 'fr' && dp?.frenchName
                          ? dp?.frenchName
                          : (dp?.name | translate)
                      }}
                    </ng-option>
                  </ng-select>
                  <ng-select
                    class="bg-white w-100 cus-select clear-disable"
                    [clearable]="true"
                    [searchable]="filter.hasOwnProperty('searchable') ? filter.searchable : true"
                    *ngSwitchCase="'autocomplete'"
                    formControlName="{{ filter.key }}"
                    [placeholder]="filter.placeholder || '' | translate"
                    [multiple]="filter.multiple || false"
                    (change)="onAutocompleteChanged($event, filter, 'true')"
                    [bindLabel]="filter.field"
                    [bindValue]="filter.filterKey || 'id'"
                    [loading]="filter.loading || false"
                    [items]="filter.results || []"
                    [virtualScroll]="true"
                    [typeahead]="filter.searchInputTerm$"
                    [clearSearchOnAdd]="true"
                    [typeToSearchText]="filter.placeholder || '' | translate"
                    [closeOnSelect]="filter.closeOnSelect || false">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      @for (item of items | slice: 0 : 1; track item) {
                        <div class="ng-value">
                          <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">
                            ×
                          </span>
                          <span class="ng-value-label">
                            {{ formateThiss(item, filter.field) | translate }}
                          </span>
                        </div>
                      }
                      @if (items?.length > 1) {
                        <div class="ng-value" [style.height]="'31.2px'">
                          <span class="ng-value-label">(+{{ items?.length - 1 }})</span>
                        </div>
                      }
                    </ng-template>
                    <ng-option
                      *ngFor="let dp of filter.options"
                      [value]="filter.field ? dp[filter.field] : dp.value">
                      {{ dp[filter.field] || dp.name }}
                    </ng-option>
                  </ng-select>
                  <ng-container *ngSwitchCase="'date-from'">
                    <div class="input-group">
                      <input
                        id="date-from"
                        type="text"
                        placeholder="{{ filter.placeholder || '' | translate }}"
                        onfocus="(this.type='date')"
                        onblur="(this.type='text')"
                        class="form-control"
                        name="input-{{ filter.key }}"
                        (change)="onDateChange($event, filter, i)"
                        (keydown)="$event.preventDefault()"
                        onclick="this.showPicker()"
                        [max]="getControlValue(filter.maxTo)"
                        [min]="getControlValue(filter.minFrom)"
                        formControlName="{{ filter.key }}" />
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'date-to'">
                    <div class="input-group">
                      <input
                        id="date-to"
                        type="text"
                        placeholder="{{ filter.placeholder || '' | translate }}"
                        onfocus="(this.type='date')"
                        onblur="(this.type='text')"
                        class="form-control"
                        name="input-{{ filter.key }}"
                        (change)="onDateChange($event, filter, i)"
                        (keydown)="$event.preventDefault()"
                        onclick="this.showPicker()"
                        [max]="getControlValue(filter.maxTo)"
                        [min]="getControlValue(filter.minFrom)"
                        formControlName="{{ filter.key }}" />
                    </div>
                  </ng-container>
                  <input
                    *ngSwitchCase="'time'"
                    type="time"
                    class="form-control"
                    name="input-{{ filter.key }}"
                    (change)="onInputChange($any($event.target).value, filter, false, i)"
                    placeholder="{{ filter.placeholder || '' | translate }}"
                    formControlName="{{ filter.key }}" />
                  <button
                    class="btn btn-outline-warning rounded btn-140"
                    type="button"
                    [ngClass]="{ active: filter?.selectedValue }"
                    (click)="onApplyFilter(filter, i)"
                    *ngSwitchCase="'sevenDays'">
                    {{ 'GENERAL.BUTTONS.LAST_7_DAYS' | translate }}
                  </button>
                  <button
                    class="btn btn-outline-danger rounded btn-104"
                    type="button"
                    [ngClass]="{ active: filter?.selectedValue }"
                    (click)="onApplyFilter(filter, i)"
                    *ngSwitchCase="'past'">
                    {{ 'GENERAL.BUTTONS.PAST' | translate }}
                  </button>
                  <button
                    class="btn btn-outline-success rounded btn-104"
                    type="button"
                    [ngClass]="{ active: filter?.selectedValue }"
                    (click)="onApplyFilter(filter, i)"
                    *ngSwitchCase="'current'">
                    {{ 'GENERAL.BUTTONS.CURRENT' | translate }}
                  </button>
                  <button
                    class="btn btn-outline-warning rounded btn-104"
                    type="button"
                    [ngClass]="{ active: filter?.selectedValue }"
                    (click)="onApplyFilter(filter, i)"
                    *ngSwitchCase="'upcoming'">
                    {{ 'GENERAL.BUTTONS.UPCOMING' | translate }}
                  </button>
                  <button
                    class="btn btn-outline-danger rounded btn-104"
                    type="button"
                    [ngClass]="{ active: filter?.selectedValue }"
                    (click)="onApplyFilter(filter, i)"
                    *ngSwitchCase="'expired'">
                    {{ 'GENERAL.BUTTONS.EXPIRED' | translate }}
                  </button>
                  <button
                    class="btn btn-outline-danger rounded btn-140"
                    type="button"
                    [ngClass]="{ active: filter?.selectedValue }"
                    (click)="onApplyFilter(filter, i)"
                    *ngSwitchCase="'yesterday'">
                    {{ 'GENERAL.BUTTONS.YESTERDAY' | translate }}
                  </button>
                  <button
                    class="btn btn-outline-success rounded btn-140"
                    type="button"
                    [ngClass]="{ active: filter?.selectedValue }"
                    (click)="onApplyFilter(filter, i)"
                    *ngSwitchCase="'today'">
                    {{ 'GENERAL.BUTTONS.TODAY' | translate }}
                  </button>
                  <button
                    class="btn btn-outline-warning rounded btn-140"
                    type="button"
                    [ngClass]="{ active: filter?.selectedValue }"
                    (click)="onApplyFilter(filter, i)"
                    *ngSwitchCase="'tomorrow'">
                    {{ 'GENERAL.BUTTONS.TOMORROW' | translate }}
                  </button>
                </ng-container>
              </div>
            </div>
            <div
              class="d-flex justify-content-end gap-2 align-items-center ms-auto filters-btns"
              [ngClass]="[isBtnBelow ? 'btn-btm' : '']">
              <button class="btn btn-link text-primary" type="reset" (click)="resetForm()">
                {{ 'GENERAL.BUTTONS.RESET' | translate }}
              </button>
              <button
                class="btn btn-primary btn-icon-text btn-140"
                type="submit"
                (click)="emitButtonEvent()"
                [disabled]="isApplyButtonDisabled()">
                {{ 'GENERAL.BUTTONS.APPLY' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </ng-container>
  </ng-container>
</div>
