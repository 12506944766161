export namespace Roles {
  export type RolesEnum = 'designer' | 'admin' | 'homeowner' | 'super_admin' | 'content_creator' | 'operator';
  export const RolesEnum = {
      Admin: 'admin' as RolesEnum,
      Designer: 'designer' as RolesEnum,
      Homeowner: 'homeowner' as RolesEnum,
      SuperAdmin: 'super_admin' as RolesEnum,
      ContentCreator: 'content_creator' as RolesEnum,
      Operator: 'operator' as RolesEnum
  };
}