import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiResponseInterface } from '@src/app/core/interfaces/api-response.interface';

import { HttpService } from '@src/app/core/services/http.service';
import { SUB_CATEGORY_BASE_URL, SUB_CATEPGORY_BY_ID_BASE_URL } from '../constants/apis_list';
import { CATEGORY_BASE_URL } from '@src/app/features/categories/core';

@Injectable({
  providedIn: 'root',
})
export class SubCategoriesRepository {
  constructor(private httpService: HttpService) { }

  getCategories(filter = {}) {
    return this.httpService.requestEntity('GET', CATEGORY_BASE_URL, filter);
  }

  search(filter = {}): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('GET', SUB_CATEGORY_BASE_URL, filter);
  }
  
  create(body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('POST', SUB_CATEGORY_BASE_URL, body);
  }

  update(id: string, body: any): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('PATCH', SUB_CATEPGORY_BY_ID_BASE_URL.replace('{id}', id), body);
  }

  getDetailById(id: string): Observable<ApiResponseInterface<null>> {
    return this.httpService.requestEntity('GET', SUB_CATEPGORY_BY_ID_BASE_URL.replace('{id}', id));
  }

  delete(id: string): Observable<ApiResponseInterface<any>> {
    return this.httpService.requestEntity('DELETE', SUB_CATEPGORY_BY_ID_BASE_URL.replace('{id}', id));
  }

}
