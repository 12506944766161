import {
  Component,
  Injector,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@app/core/base/base.component';
import { FiltersComponent } from '@components/filters/filters.component';
import { ApiEndPointConfiguration } from '@app/app-types';
import { updateInputBindingOnChanges } from '@app/core/shared/helpers';
import { AllowedTableActions } from '@src/app/core/interfaces/table-actions.interface';
import moment from 'moment';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { STATUS_ARRAY } from '@src/app/core/constants';
import { USER_MANAGEMENT_STATUS_UPDATE } from '@src/app/core/constants/apis-list.constant';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import { RequestActvationType } from '../../interfaces/app-types';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  public confirmEventAction: 'active' | 'delete' | null = null;
  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;
  @Input() stopLoadUp = true;
  @Input() searchable = true;
  @Input() itemsExport = false;
  @Input() crmItemsExport = false;
  @Input() sortKey: string = this.constantList.DEFAULT_SORT_KEY;
  @Input() sortOrder: string = '';
  @Input() enableTableAction: boolean = false;
  @Input() showZoneButton: boolean = false;
  @Input() showInfoImage: boolean = false;
  @Input() infoImageUrl: string = '';
  @Input() infoMessage: string = '';
  @Input() isLocalActions: boolean = false;
  @Input() isLoadDataFromStart: boolean = true;

  @Input() displayedColumnsViewArray: any[] = [];
  @Input() showAddDropDown: boolean = false;
  @Input() notFoundMessage: string = '';
  @Input() addDropDownData: any[] = [];
  @Input() alignment: string = '';
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() tableAction: EventEmitter<any> = new EventEmitter<any>();

  /**
   * API configuration object
   * @type {url: string; endpoint: string; method: string; contentType: string}
   */
  @Input()
  endPointConfiguration: ApiEndPointConfiguration = {
    url: '',
    endpoint: '',
    method: 'GET',
    contentType: 'application/json',
  };

  /*
   * The following used to hold the inputs for header
   * */
  @Input() title: any = null;
  @Input() link: any = null;
  @Input() queryParams: any = null;
  @Input() filtersPerRow = 3;
  @Input() linkText: any = null;
  @Input() linkIcon: any = null;
  @Input() allowAction: boolean = false;
  @Input() maxFiltersOnFirstRow: number = 3;
  @Input() hideFilter: boolean = false;
  @Input() hideFilterHeader: boolean = false;
  @Input() hideStyles: boolean = false;
  @Input() hidePagination: boolean = false;
  @Input() hideLoader: boolean = false;
  @Input() showCreateButton: boolean = false;
  @Input() showRefreshButton: boolean = false;
  @Input() exportDisabledOnNoRecord: boolean = false; // if true, export button will be disabled if no record found
  @Input() searchPlaceholder: string = 'Search';
  @Input() isBtnBelow: boolean = false;
  @Input() canUpdateStatus: boolean = true;
  @Input() canUpdateProfileStatus: boolean = true;
  /*
   * The following holds the inputs for the local table items
   * */
  @Input() defaultParams: any = null;
  @Input() localItems: any = [];
  @Input() isLocalTable: boolean = false;
  @Input() showTableFooter: boolean = false;
  @Input() footerData: any = null;
  @Output() emitFooterData: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Optional Request body
   * @type {{}}
   */
  @Input() requestBody: any = {};
  @Input() sendIntercity: boolean = false;
  @Input() intercityKey: string = 'filter.intercity';
  @Input() noResultFoundMsg: null | string = '';

  @Input() paramFilters: any[] = [];
  @Input() isInvoiceTable: boolean = false;
  @Input() allowedTableActions: AllowedTableActions = {};
  @Output() elementClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() buttonClick: EventEmitter<string | null> = new EventEmitter<string | null>();

  @ViewChild(FiltersComponent, { static: true }) filtersRef!: FiltersComponent;

  public statusArray = STATUS_ARRAY;
  public params: any = {};
  public bulkSelectList: any[] = [];
  public displayedColumns: any[] = [];
  public message: string = 'MSGS.GENERAL.ARE_YOU_SURE';
  public extraIcon: string = 'icon-warning-2';

  public pageIndex: number = this.constantList.DEFAULT_PAGE_INDEX;
  public pageSize: number = this.constantList.NUMBER_RECORDS_PER_PAGE;
  public totalElements: number = 0;
  public totalPages: number = 0;
  public isLoading: boolean = false;
  public resetFilters: boolean = false;
  public isFirstTIme: boolean = true;
  public backupLocalItems: any;

  public itemList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public itemsList: any[] = [];


  private _onDestroy$: Subject<void> = new Subject<void>();
  // for editable table
  public backupFormData: any = {};
  public isAddNewEnabled: boolean = false;
  @Input() formData: any;
  @Input() showEditableTableFooter: boolean = false;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.setDisplayColumns();
    if (this.hideFilterHeader) {
      this.onSearchClick({ params: this.params, filters: this.paramFilters });
    }
    if (!this.stopLoadUp && !this.isLocalTable) {
      this.loadResourcesPage();
    } else if (this.isLocalTable) {
      this.setTableData(this.localItems);
      this.calculatePagination();
    }
    if (this.isLocalTable) {
      this.itemsList = this.localItems.map((item: any) => {
        item.isChecked = false;
        return item;
      });
    } else {
      this.itemList$.subscribe(data => {
        this.itemsList = data;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    updateInputBindingOnChanges(this, changes, (newInputs: any) => {
      Object.assign(this, newInputs);
      if (newInputs.isLocalTable) {
        this.calculatePagination();
      }
      if (newInputs.hasOwnProperty('displayedColumnsViewArray')) {
        this.setDisplayColumns(newInputs.displayedColumnsViewArray);
      }
    });
    if (changes['defaultParams']) {
      const prev = changes['defaultParams'].previousValue;
      const curr = changes['defaultParams'].currentValue;
      if (prev !== curr) {
        if (curr) {
          this.loadResourcesPage();
        }
      }
    } else if (changes['endPointConfiguration']) {
      const prev = changes['endPointConfiguration'].previousValue;
      const curr = changes['endPointConfiguration'].currentValue;

      if (prev !== curr) {
        if (curr) {
          if (!this.endPointConfiguration.url && this.showInfoImage && this.infoImageUrl) {
            this.endPointConfiguration = curr;
            this.loadResourcesPage();
          }
          this.endPointConfiguration = curr;
        }
      }
    } else if (changes['formData']) {
      // for editable table
      const prev = changes['formData'].previousValue;
      const curr = changes['formData'].currentValue;

      if (prev !== curr) {
        if (curr) {
          this.formData = curr;
          this.backupFormData = this.formData;
        }
      }
    }
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
  // for editable table start
  onAddNewEnabled() {
    this.isAddNewEnabled = true;
    for (let key in this.formData) {
      this.backupFormData[key] = this.formData[key];
    }
    this.resetEditableItems();
    return;
  }

  resetEditableItems() {
    let tableValues = this.getTableData();
    for (let key in this.formData) {
      this.backupFormData[key] = this.formData[key];
    }
    for (let indx = 0; indx < tableValues.length; indx++) {
      tableValues[indx]['editableView'] = false;
    }
    this.itemList$.next(tableValues);
  }
  // for editable end

  pageChanged($event: { type: string; value: number }) {
    switch ($event.type) {
      case 'page':
        this.pageIndex = $event.value;
        if (this.isLocalTable) {
          this.calculatePagination();
        } else {
          this.loadResourcesPage(this.pageIndex);
        }
        break;
      case 'size':
        this.pageSize = Number($event.value);
        if (this.isLocalTable) {
          this.calculatePagination();
        } else {
          this.loadResourcesPage();
        }
        break;
    }
  }

  emitButtonEvent(event: any) {
    // info message should be shown only once filter is selected
    if (event.reset && this.infoImageUrl) {
      this.showInfoImage = true;
    } else {
      this.showInfoImage = false;
    }
    // end

    if (event === 'create') {
      this.buttonClick.emit(event);
    } else if (event === 'refresh') {
      this.loadResourcesPage();
    } else {
      let filters = event.filters;
      let filtersParams = this.helperService.clone(event.params);

      if (
        Object?.keys(filtersParams)?.length === 0 ||
        (Object?.keys(filtersParams)?.length === 1 && 'search' in filtersParams)
      ) {
      } else {
        // delete generated keys using mutual key
        filters.forEach((filter: any) => {
          const mutualKey = filter.mutualKey;
          const key = filter.key;
          if (mutualKey && key in filtersParams && mutualKey in filtersParams) {
            if (filter.resetKey !== mutualKey) {
              delete filtersParams[mutualKey];
            }
          }
        });
        let checkReaptedKey: any;
        let deletedValue: any;
        // Iterate through filters and update params
        filters.forEach((filter: any, index: any) => {
          const mutualKey = filter.mutualKey;
          const operation = filter.operation;
          const selectedValue = filter.selectedValue;
          // Check if mutualKey exists in params and update accordingly
          if (mutualKey) {
            if (mutualKey in filtersParams) {
              checkReaptedKey = mutualKey;
              if (selectedValue) {
                const startDate = moment(filtersParams[mutualKey].split(':')[1], 'YYYY-MM-DD')
                  .startOf('day')
                  .format('YYYY-MM-DD HH:mm');
                const endDate = moment(selectedValue, 'YYYY-MM-DD')
                  .endOf('day')
                  .format('YYYY-MM-DD HH:mm');
                filtersParams[mutualKey] = `${
                  filtersParams[mutualKey].split(':')[0]
                }:${startDate},${endDate}`;
              } else {
                if (
                  filtersParams[mutualKey].split(':').length < 4 &&
                  moment().isBefore(moment(filtersParams[mutualKey].split(':')[1]))
                ) {
                  filtersParams[mutualKey] += `, ${filtersParams[mutualKey]
                    .split(':')[1]
                    ?.split(' ')[0]} 23:59`;
                } else {
                  if (
                    filtersParams[mutualKey].split(':')[1] &&
                    filtersParams[mutualKey].split(':')[1].split(',').length < 1
                  ) {
                    // only one date
                    filtersParams[mutualKey] += `,${moment().format('YYYY-MM-DD')} 23:59`;
                  } else if (
                    filtersParams[mutualKey].split(':')[1] &&
                    filtersParams[mutualKey].split(':')[1].split(',').length === 1
                  ) {
                    let startDate = moment(
                      filtersParams[mutualKey].split(':')[1],
                      'YYYY-MM-DD',
                    ).format('YYYY-MM-DD HH:mm');
                    let endDate = moment(
                      filtersParams[mutualKey].split(':')[1],
                      'YYYY-MM-DD',
                    ).format('YYYY-MM-DD HH:mm');
                    if (moment(startDate).isSame(endDate)) {
                      endDate = moment(filtersParams[mutualKey].split(':')[1], 'YYYY-MM-DD')
                        .add(2, 'years')
                        .endOf('day')
                        .format('YYYY-MM-DD HH:mm');
                    } else {
                      startDate = moment(filtersParams[mutualKey].split(':')[1], 'YYYY-MM-DD')
                        .startOf('day')
                        .format('YYYY-MM-DD HH:mm');
                      endDate = moment(filtersParams[mutualKey].split(':')[1], 'YYYY-MM-DD')
                        .endOf('day')
                        .format('YYYY-MM-DD HH:mm');
                    }
                    filtersParams[mutualKey] = `${
                      filtersParams[mutualKey].split(':')[0]
                    }:${startDate},${endDate}`;
                  }
                }
              }
            } else if (selectedValue) {
              if (operation) {
                if (operation?.includes('btw')) {
                  const startDate = moment(selectedValue, 'YYYY-MM-DD')
                    .startOf('day')
                    .format('YYYY-MM-DD HH:mm');
                  const endDate = moment(selectedValue, 'YYYY-MM-DD')
                    .endOf('day')
                    .format('YYYY-MM-DD HH:mm');

                  filtersParams[mutualKey] = `${operation}${startDate},${endDate}`;
                } else {
                  filtersParams[mutualKey] = `${operation}${selectedValue}`;
                }
              } else {
                if (deletedValue) {
                  const startDate = moment(deletedValue.split(':')[1], 'YYYY-MM-DD')
                    .startOf('day')
                    .format('YYYY-MM-DD HH:mm');
                  const endDate = moment(selectedValue, 'YYYY-MM-DD')
                    .endOf('day')
                    .format('YYYY-MM-DD HH:mm');

                  filtersParams[mutualKey] = `${
                    deletedValue.split(':')[0]
                  }:${startDate},${endDate}`;
                } else {
                  let startDate = moment(selectedValue, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm');
                  let endDate = moment(selectedValue, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm');
                  if (moment(startDate).isSame(endDate)) {
                    endDate = moment(selectedValue, 'YYYY-MM-DD')
                      .endOf('day')
                      .format('YYYY-MM-DD HH:mm');
                    startDate = moment(selectedValue, 'YYYY-MM-DD')
                      .subtract(2, 'years')
                      .startOf('day')
                      .format('YYYY-MM-DD HH:mm');
                  } else {
                    startDate = moment(selectedValue, 'YYYY-MM-DD')
                      .startOf('day')
                      .format('YYYY-MM-DD HH:mm');
                    endDate = moment(selectedValue, 'YYYY-MM-DD')
                      .endOf('day')
                      .format('YYYY-MM-DD HH:mm');
                  }
                  filtersParams[mutualKey] = `$btw:${startDate},${endDate}`;
                }
              }
            }

            if (filtersParams[filter.key]) {
              if (filter.resetKey === mutualKey && checkReaptedKey === mutualKey && !deletedValue) {
                deletedValue = filtersParams[filter.key];
              }
              const strOccurrences = this.countOccurrences(
                filtersParams[filter.key],
                selectedValue,
              );
              if (!(strOccurrences > 1) || !selectedValue) {
                delete filtersParams[filter.key]; // Remove the original key (e.g., fromDate, toDate) from params
              }
            }
          } else {
            if (!(filter.key in filtersParams) && selectedValue && operation?.includes('btw')) {
              filtersParams[filter.key] = `${operation}${selectedValue}`;
            }
            if (filter.extraFilter && selectedValue) {
              filtersParams[filter.extraFilter.key] = filter.extraFilter.value;
            }
          }
        });
      }
      event.params = filtersParams;
      this.buttonClick.emit(event);
      if (typeof event !== 'string') {
        this.params = event?.params;
        if (event.reset && this.infoImageUrl) {
          this.itemList$.next([]);
          this.totalPages = 0;
          this.totalElements = 0;

          this.resetFilters = false;
          return;
        } else {
          this.loadResourcesPage(1);
        }
      }
    }
  }

  countOccurrences(mainStr: string, searchStr: string) {
    const occurrences = mainStr.split(searchStr).length - 1;
    return occurrences;
  }

  onEmitAction(action: any) {
    if (action?.key == 'download_selected_rows' || action?.key === 'export') {
      const index = this.pageIndex || this.constantList.DEFAULT_PAGE_INDEX;
      if (this.params && !this.params[this.constantList.SEARCH]) {
        this.params[this.constantList.SEARCH] = '';
      }
      const body: any = { ...this.params };
      body['page'] = index;
      body['limit'] = this.pageSize || this.constantList.NUMBER_RECORDS_PER_PAGE;
      if (this?.sortKey) {
        body['sortBy'] = `${this.sortKey}:${
          this.sortOrder || this.constantList.DEFAULT_SORT_ORDER
        }`;
      }
      if (Object.keys(this.requestBody).length > 0) {
        for (const property in this.requestBody) {
          body[property] = this.requestBody[property];
          if (this.params) {
            this.params[property] = body[property];
          }
          if (this.filtersRef) {
            this.filtersRef.params[property] = body[property];
          }
        }
      }
      action.params = body;
    }
    if (
      action?.key === 'download_selected_rows' ||
      action?.key === 'download_template' ||
      action?.key === 'import' ||
      action?.key === 'export'
    ) {
      this.tableAction.emit(action);
    } else if (action?.key === 'localExport') {
      if (this.confirmDialCompRef) {
        this.message = 'COMPONENTS.SCHEDULE.EMPLOYEE_ENGAGEMENT.EXPORT_TITLE';
        this.extraIcon = 'icon-export';
        action.data = this.localItems;
        action.columns = this.displayedColumnsViewArray?.slice(1);
        action.filters = this.params;
        this.confirmEventAction = action?.actionName?.toLowerCase();
        this.confirmDialCompRef.openDialog(action);
      }
    } else {
      if (this.bulkSelectList.length > 0) {
        if (this.confirmDialCompRef) {
          this.confirmEventAction = action?.actionName?.toLowerCase();
          this.confirmDialCompRef.openDialog(action);
        }
      } else {
        this.httpService.showMessage(this.translate.instant('MSGS.GENERAL.SELECT_ROW'), 'error');
      }
    }
  }

  /**
   * Process the modal close event.
   *
   * @param {any} event - The event object containing the action and data.
   * @return {void} This function does not return anything.
   */
  public processModalClose(event: any) {
    if (event.action === 'confirm') {
      this.tableAction.emit(event.data);
    }
    setTimeout(() => {
      this.message = 'MSGS.GENERAL.ARE_YOU_SURE';
      this.extraIcon = 'icon-warning-2';
    }, 1000);
  }

  /**
   * Loads resources page based on the provided page index.
   *
   * @param {any} pageIndex - Optional. The index of the page to load. If not provided, the default page index is used.
   * @return {void} This function does not return anything.
   */
  loadResourcesPage(pageIndex?: any, isSortable: boolean = false): void {
    if (!this.endPointConfiguration.url) {
      return;
    }
    if (!this.isLocalTable) {
      this.isLoading = true;
      const index =
        pageIndex !== undefined
          ? pageIndex
          : this.pageIndex || this.constantList.DEFAULT_PAGE_INDEX;
      if (this.params && !this.params[this.constantList.SEARCH]) {
        this.params[this.constantList.SEARCH] = '';
      }

      const body: any = {
        ...(this.defaultParams ? this.defaultParams : {}),
        ...this.endPointConfiguration.body,
        ...this.params,
      };
      body['page'] = index;
      body['limit'] = this.pageSize || this.constantList.NUMBER_RECORDS_PER_PAGE;
      if (this.sortKey) {
        body['sortBy'] = `${this.sortKey}:${
          this.sortOrder ? this.sortOrder : this.constantList.DEFAULT_SORT_ORDER
        }`;
      }
      if (this.sendIntercity) {
        body[this.intercityKey] = this.sharedDataService.selectIntercity || false;
      }
      if (Object.keys(this.requestBody).length > 0) {
        for (const property in this.requestBody) {
          body[property] = this.requestBody[property];
          if (this.params) {
            this.params[property] = body[property];
          }
          if (this.filtersRef) {
            this.filtersRef.params[property] = body[property];
          }
        }
      }

      this.httpService
        .requestEntity(
          this.endPointConfiguration.method || 'GET',
          this.endPointConfiguration.url,
          body,
        )
        .pipe(takeUntil(this._onDestroy$))
        .subscribe({
          /**
           * Updates the local items, total pages, total elements, and item list based on the response.
           *
           * @param {any} res - The response object containing the data.
           */
          next: (response: unknown) => {
            const res = response as ApiResponseInterface<any>;
            if (!this.showInfoImage) {
              if (Array.isArray(res?.body)) {
                this.localItems = [];
                this.totalPages = Math.ceil(res?.body.length / this.pageSize) || 0;
                this.totalElements = res?.body?.length || 0;
                this.localItems = res?.body || [];
                this.backupLocalItems = this.helperService.clone(this.localItems);
                this.isLocalTable = true;
                if (this.showTableFooter && this.localItems?.length > 0) {
                  this.calculateFooterValue();
                }
              } else {
                this.itemList$.next([]);
                this.totalPages = res?.body?.meta?.totalPages || 0;
                this.totalElements = res?.body?.meta?.totalItems || res?.body?.data?.length || 0;
                // Adding delay to reflect changes in view
                setTimeout(() => {
                  this.itemList$.next(res?.body?.data || []);
                });
              }
              if (res?.body?.data?.length > 0) {
                res.body.data = res.body.data.map((item: any) => {
                  item.isChecked = false;
                  return item;
                });
              }
            } else if (this.showInfoImage) {
              this.localItems = [];
              this.backupLocalItems = null;
              this.itemList$.next([]);
              this.totalPages = 0;
              this.totalElements = 0;
            }
            this.resetFilters = false;
          },
          /**
           * Executes a callback function when an error occurs.
           *
           * @return {void} This function does not return anything.
           */
          error: () => {
            setTimeout(() => {
              this.isLoading = false;
            });
            this.itemList$.next([]);
            this.resetFilters = false;
          },
          /**
           * Executes a callback function when the operation is complete.
           *
           * This function sets the `isLoading` property to `false` after a delay of 0 milliseconds using `setTimeout`.
           * It also sets the `resetFilters` property to `false`.
           *
           * @return {void} This function does not return anything.
           */
          complete: () => {
            setTimeout(() => {
              this.isLoading = false;
            });
            this.resetFilters = false;
          },
        });
    } else if (isSortable) {
      // for createdAt transaction settlement (Fine, Ticket)
      this.localItems = this.localItems.sort((a: any, b: any) => {
        const dateA: any = moment(a.createdAt, 'DD/MM/YYYY HH:mm');
        const dateB: any = moment(b.createdAt, 'DD/MM/YYYY HH:mm');
        return this.sortOrder === 'ASC' ? dateA - dateB : dateB - dateA; // Ascending order; use `dateB - dateA` for descending order
      });
      if (this.showTableFooter && this.localItems?.length > 0) {
        this.calculateFooterValue();
      }
    }
  }

  /**
   * Handles the click event on the search button.
   *
   * @param {Object} $event - The event object containing the parameters and filters.
   * @param {any} $event.params - The parameters for the search.
   * @param {any} $event.filters - The filters for the search.
   * @return {void} This function does not return anything.
   */
  onSearchClick($event: { params: any; filters: any }) {
    if (this.showInfoImage && !$event.params.search && this.isFirstTIme && this.infoImageUrl) {
      this.isFirstTIme = false;
      return;
    }

    if (this.isLocalActions) {
      const search = $event.params?.search?.toLowerCase();
      const filterItems = (item: any) =>
        this.displayedColumnsViewArray.some(column => {
          const value = column.key ? this.getValueFromElement(item, column.key, column) : undefined;
          return value?.toString().toLowerCase().includes(search);
        });

      if (search) {
        this.localItems = this.backupLocalItems.filter(filterItems);
      } else {
        this.localItems = this.backupLocalItems
          ? this.helperService.clone(this.backupLocalItems)
          : [];
        if (!this.backupLocalItems) {
          this.clearBulkSelectList();
          this.params = $event.params;
          this.setDefaultPageIndex();
          this.loadResourcesPage(this.pageIndex);
        }
      }
    } else {
      this.clearBulkSelectList();
      this.params = $event.params;
      this.setDefaultPageIndex();
      if (this.isLoadDataFromStart) {
        this.loadResourcesPage(this.pageIndex);
      }
    }
  }

  getValueFromElement(item: any, key: string, column: any) {
    if (key === 'data') {
      return column.callback(item);
    } else if (key.includes('.')) {
      const [key1, key2] = key.split('.');
      return item[key1]?.[key2];
    } else {
      return item[key];
    }
  }

  /**
   * Emits the given event to the exportClick output.
   *
   * @param {string} event - The event to emit.
   * @return {void} This function does not return anything.
   */
  getBtnClickType(event: string) {
    this.exportClick.emit(event);
  }

  /**
   * Unchecks a row in the localItems array based on the provided element's id.
   *
   * @param {any} element - The element containing the id of the row to be unchecked.
   */
  unCheckRows(element: any) {
    let index = this.localItems.findIndex((i: any) => i.id == element.id);
    if (index !== -1) {
      this.localItems[index].isChecked = false;
    }
  }

  multipleButtonsHandler(e: any, button: any, index?: number) {
    if (e?.id && button.key === 'delete' && (index || index === 0)) {
      this.elementClick.emit({
        element: e,
        action: button.key,
        value: '',
        id: '',
        editFormData: this.backupFormData,
      });
      return;
    } else if (e?.id && button.key === 'edit' && (index || index === 0)) {
      let tableValues = this.getTableData();
      for (let indx = 0; indx < tableValues.length; indx++) {
        tableValues[indx]['editableView'] = false;
      }
      for (let key in this.formData) {
        this.backupFormData[key] = e[key];
      }

      tableValues[index]['editableView'] = true;
      this.itemList$.next(tableValues);
      this.isAddNewEnabled = false;
    } else if (!button.iconCancelClass && this.backupFormData) {
      setTimeout(() => {
        this.isAddNewEnabled = false;
        this.resetEditableItems();
      }, 100);
    } else if (button.iconCancelClass) {
      if (index || index === 0) {
        let tableValues = this.getTableData();
        tableValues[index]['editableView'] = false;
        this.itemList$.next(tableValues);
      } else {
        this.isAddNewEnabled = false;
      }
    }
    button.clickHandler(e, button, this.backupFormData);
  }

  /**
   * Emits an event with the given element, action, value, and optional id.
   *
   * @param {any} element - The element to emit with the event.
   * @param {Object} action - The action object containing the key and value.
   * @param {string} action.key - The key of the action.
   * @param {string} action.value - The value of the action.
   * @param {any} value - The value to emit with the event.
   * @param {string} [id] - The optional id to emit with the event.
   * @return {void} This function does not return anything.
   */
  emitEvent(
    element: any,
    action: { key: string; value: string; type?: string },
    value: any,
    id?: string,
    index?: number,
  ) {
    if (!id && value === 'editView' && (index || index === 0)) {
      let tableValues = this.getTableData();
      for (let key in this.formData) {
        this.backupFormData[key] = element[key];
      }

      tableValues[index]['editableView'] = true;
      this.itemList$.next(tableValues);
      this.isAddNewEnabled = false;
      return;
    }

    if (!id && value === 'cancel_editable') {
      if (index || index === 0) {
        let tableValues = this.getTableData();
        tableValues[index]['editableView'] = false;
        this.itemList$.next(tableValues);
      } else {
        this.isAddNewEnabled = false;
      }
      return;
    }
    if (action?.key === 'booking_reference' && action?.type === 'hyper_link') {
      if (!element.booking) return;
    }
    this.elementClick.emit({
      element: element,
      action: action.key,
      value,
      id,
      editFormData: this.backupFormData,
    });
    if (!id && value === 'editable' && this.backupFormData) {
      setTimeout(() => {
        this.isAddNewEnabled = false;
        this.resetEditableItems();
      }, 100);
    }
  }

  /**
   * Emits an event with the given element and action.
   *
   * @param {any} element - The element to emit with the event.
   * @param {Object} action - The action object containing the key and value.
   * @param {string} action.key - The key of the action.
   * @param {string} action.value - The value of the action.
   * @return {void} This function does not return anything.
   */
  emitLinkEvent(element: any, action: { key: string; value: string }) {
    this.elementClick.emit({ element: element, action: action });
  }

  /**
   * Constructs a nested object based on the given element and column.
   *
   * @param {any} element - The element to construct the nested object from.
   * @param {Object} column - The column object containing the key, value, type, map, and callback.
   * @param {string} column.key - The key of the column.
   * @param {string} column.value - The value of the column.
   * @param {string} column.type - The type of the column.
   * @param {any} column.map - The map object for the column.
   * @param {Function} column.callback - The callback function for the column.
   * @return {any} The constructed nested object.
   */
  constructNestedObject(
    element: any,
    column: {
      key: string;
      value: string;
      type: string;
      map: any;
      callback: Function;
    },
  ) {
    if (!column.key && !column.callback) {
      return '';
    } else if (!column.key && column.callback) {
      return column.callback.call(column.callback, <any>element);
    }
    const key =
      column.key.split('.').length > 1
        ? this.getNestedPropertyValue(element, column.key)
        : column.key;
    if (key && key !== column.key) {
      return column.map ? (column.map[key] ? column.map[key] : key) : key;
    }
    if ((!key || !element[key]) && !column.callback && !column.map) {
      return element[key] != undefined && element[key] != null ? element[key] : '';
    }
    if ((!key || !element[key]) && column.callback) {
      return column.callback.call(column.callback, <any>element);
    }
    if (column.key.indexOf('.') > -1 && !column.map) {
      return key;
    }
    if (!element[key] && !column.map) {
      return element[key] != undefined && element[key] != null ? element[key] : '';
    }
    if (column.callback) {
      return column.callback.call(column.callback, <any>element[key]);
    }
    return column.map
      ? column.map[key]
        ? column.map[key]
        : column.map[element[key]]
      : element[key];
  }

  /**
   * Constructs an image object based on the given element and column.
   *
   * @param {any} element - The element to construct the image from.
   * @param {Object} column - The column object containing the key.
   * @param {string} column.key - The key of the column.
   * @return {any} The constructed image object or undefined if the element does not have the specified key.
   */
  constructImage(element: any, column: { key: string }): any {
    return element[column.key] || undefined;
  }

  /**
   * Retrieves the value of a nested property from an object.
   *
   * @param {any} theObject - The object to retrieve the nested property from.
   * @param {string} path - The path to the nested property, using dot notation or bracket notation.
   * @param {string} [separator='.'] - The separator used to split the path. Default is dot ('.').
   * @return {string} The value of the nested property, or an empty string if the property does not exist.
   */
  getNestedPropertyValue(theObject: any, path: string, separator = '.'): string {
    try {
      separator = separator || '.';
      return path
        .replace('[', separator)
        .replace(']', '')
        .split(separator)
        .reduce(function (obj, property) {
          return obj[property];
        }, theObject);
    } catch (err) {
      return '';
    }
  }

  /**
   * Sorts the table based on the provided column.
   *
   * @param {any} column - The column to sort the table by.
   * @return {void} This function does not return a value.
   */
  sortTable(column: any) {
    if (column.sortable) {
      this.sortKey = column.sortKey || column.key;
      this.sortOrder =
        this.sortOrder === this.constantList.DEFAULT_SORT_ORDER
          ? 'ASC'
          : this.constantList.DEFAULT_SORT_ORDER;
      this.setDefaultPageIndex();
      if (column.sortOnLocal) {
        this.loadResourcesPage(null, column.sortOnLocal);
      } else {
        this.loadResourcesPage();
      }
    }
  }

  /**
   * Selects all rows in the itemsList and adds them to the bulkSelectList.
   * Emits an event with the updated bulkSelectList.
   *
   * @return {void} This function does not return a value.
   */
  selectAll(): void {
    if (this.itemsList.length == 0) {
      this.itemsList = this.localItems;
    }
    this.itemsList.forEach((row: any) => {
      row.isChecked = true;

      // Check if the row is already in the bulkSelectList
      const existingIndex: number = this.bulkSelectList.findIndex((v: any) => v === row);
      if (existingIndex === -1) {
        this.bulkSelectList.push(row);
      }
    });

    this.emitEvent(this.bulkSelectList, { key: 'selected Rows', value: '' }, null);
  }

  /**
   * Handles the change event when the select all checkbox is clicked.
   * If the checkbox is checked, selects all rows in the itemsList and adds them to the bulkSelectList.
   * Emits an event with the updated bulkSelectList.
   * If the checkbox is unchecked, unchecks all checkboxes and clears the bulkSelectList.
   *
   * @param {any} e - The event object containing the target property.
   * @return {void} This function does not return a value.
   */
  onSelectAllChanged(e: any): void {
    if (e.target.checked) {
      this.selectAll();
    } else {
      // Uncheck all checkboxes and clear bulkSelectList
      this.itemsList.forEach((row: any) => {
        row.isChecked = false;
      });

      this.bulkSelectList = [];
      this.emitEvent(this.bulkSelectList, { key: 'selected Rows', value: '' }, null);
    }
  }

  /**
   * Handles the change event when a row checkbox is clicked.
   * Toggles the checkbox value and updates the bulkSelectList accordingly.
   * Emits an event with the updated bulkSelectList.
   *
   * @param {any} value - The row object whose checkbox is being toggled.
   * @return {void} This function does not return a value.
   */
  onRowCheckChanged(value: any): void {
    value.isChecked = !value.isChecked;
    const valueIndex: number = this.bulkSelectList.findIndex((v: any) => v === value);

    if (value.isChecked && valueIndex === -1) {
      // If the checkbox is checked and the row is not in the bulkSelectList, add it
      this.bulkSelectList.push(value);
    } else if (!value.isChecked && valueIndex > -1) {
      // If the checkbox is unchecked and the row is in the bulkSelectList, remove it
      this.bulkSelectList.splice(valueIndex, 1);
    }

    const allChecked = this.itemsList.every((row: any) => row.isChecked);
    if (allChecked) {
      this.bulkSelectList = [...this.itemsList];
    }

    this.emitEvent(this.bulkSelectList, { key: 'selected Rows', value: '' }, null);
  }

  /**
   * Retrieves the table data.
   *
   * @return {any[]} The table data. If the table is local, returns the local items. Otherwise, returns the value of the itemList$ observable.
   */
  getTableData(): any[] {
    if (this.isLocalTable) {
      return this.localItems;
    }
    return this.itemList$.value;
  }

  /**
   * Checking type of column key
   *
   * @param key   typeof any | undefined | boolean | string ..
   * @returns     string based type
   */
  public getColumnKeyType(key: any) {
    return typeof key;
  }

  /**
   * Sets the table data.
   *
   * @param {any[]} [items=[]] - The items to set as the table data. Defaults to an empty array.
   * @return {void} This function does not return anything.
   */
  setTableData(items: any[] = []): void {
    if (this.isLocalTable) {
      this.localItems = items;
      this.backupLocalItems = this.helperService.clone(this.localItems);
    }
    this.itemList$.next(items);
  }

  /**
   * Updates an element at the specified index in the table data.
   *
   * @param {number} index - The index of the element to update.
   * @param {any} [element={}] - The new element to replace the existing one. Defaults to an empty object.
   * @return {void} This function does not return anything.
   */
  updateElementByIndex(index: number, element: any = {}): void {
    const value = this.isLocalTable ? [...this.localItems] : [...this.itemList$.value];
    value[index] = element;
    if (this.isLocalTable) {
      this.localItems = [];
      setTimeout(() => {
        this.localItems = [...value];
        this.backupLocalItems = this.helperService.clone(this.localItems);
      });
    } else {
      this.itemList$.next([]);
      setTimeout(() => {
        this.itemList$.next(value);
      });
    }
  }

  /**
   * Retrieves the title for a given element and column.
   *
   * @param {any} element - The element to retrieve the title from.
   * @param {any} column - The column object containing the key and value.
   * @return {string} The title of the element, or an empty string if the result is neither a string nor an object.
   */
  /**
   * Retrieves the title for a given element and column.
   *
   * @param {any} element - The element to retrieve the title from.
   * @param {any} column - The column object containing the key and value.
   * @return {string} The title of the element, or an empty string if the result is neither a string nor an object.
   */
  getTitle(element: any, column: any): string {
    const result = this.constructNestedObject(element, column);
    if (typeof result === 'string') {
      return result; // If result is a string, return it directly
    } else if (typeof result === 'object') {
      return this.helperService.getTranslatedText(result) || '';
    } else {
      return ''; // Return an empty string if the result is neither string nor object
    }
  }

  /**
   * Checks if the given data contains a user with the 'super_admin' role.
   *
   * @param {any} data - The data to check for the 'super_admin' role.
   * @return {boolean} Returns true if the data contains a user with the 'super_admin' role, otherwise false.
   */
  private hasSuperAdminRole(data: any) {
    let hasSuperAdmin = false;
    data?.forEach((item: any) => {
      if (item?.roles?.some((role: any) => role?.code === 'super_admin')) {
        hasSuperAdmin = true;
      }
    });
    return hasSuperAdmin;
  }

  /**
   * Updates the status of a resource based on the provided parameters.
   *
   * @param {string} status - The new status for the resource.
   * @param {string} id - The ID of the resource.
   * @param {any} element - The resource element.
   * @param {string} endpoint - The endpoint for updating the status.
   * @param {string} success - The success message to display.
   * @param {string} error - The error message to display.
   * @return {void} This function does not return anything.
   */
  public updateStatus(
    status: string,
    id: string,
    element: any,
    endpoint: string,
    success: string,
    error: string,
    successSecond?: string,
    apiCallType?: any
  ) {
    if (status && (!id || element?.editableView)) {
      this.backupFormData['isActivated'] = status === 'inactive' ? false : true;
      return;
    }
    if (endpoint == USER_MANAGEMENT_STATUS_UPDATE) {
      let user: any = this.helperService.getCurrentUser();
      if (id == user?.id) {
        this.httpService.showMessage(
          this.translate.instant('MSGS.USERS.OWN_PROFILE_DELETION'),
          'error',
        );
        return;
      }

      if (!this.hasSuperAdminRole([user])) {
        if (element?.roles.length > 0) {
          let super_Admin = false;

          element.roles.forEach((role: any) => {
            if (role?.code && role?.code.includes('super_admin')) {
              super_Admin = true;
            }
          });

          if (super_Admin) {
            this.httpService.showMessage(
              this.translate.instant('MSGS.USERS.SUPER_ADMIN_STATUS_CHANGE'),
              'error',
            );
            return;
          }
        }
      }
    }
    let apiCall;
    if(apiCallType == RequestActvationType.RESOURCE_URL){
      const formData = new FormData();
      const headers = new HttpHeaders({
        'accept': 'application/json',
      });
      formData.append('isActivated', `${status === 'inactive' ? false : true}`);
      apiCall =   this.httpService
      .requestEntity('PATCH', endpoint.replace('{id}', id),  formData, headers)
    } else {
    apiCall =   this.httpService
      .requestEntity('PATCH', endpoint.replace('{id}', id), {
        status: status,
      })
    }

    apiCall.pipe(this.destroy$())
      .subscribe({
        /**
         * Executes the next callback function asynchronously. If the response status is equal to the SUCCESS_STATUS constant,
         * it shows a success message using the httpService.showMessage method and calls the loadResourcesPage method.
         * Otherwise, it shows an error message using the httpService.showMessage method.
         *
         * @param {any} res - The response object.
         * @return {void} This function does not return anything.
         */
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;

          if (res.status === this.constantList.SUCCESS_STATUS) {
            if (status === 'inactive' && successSecond || status === 'Unpublished' && successSecond ) {
              this.httpService.showMessage(successSecond, 'success');
            } else {
              this.httpService.showMessage(success, 'success');
            }
            this.loadResourcesPage();
          } else {
            this.httpService.showMessage(error, 'error');
          }
        },
      });
  }
  /**
   * Sets the display columns based on the provided columns array.
   *
   * @param {any[]} [columns=this.displayedColumnsViewArray] - The array of columns to set as the display columns. Defaults to the value of `this.displayedColumnsViewArray`.
   * @return {void} This function does not return anything.
   */
  private setDisplayColumns(columns: any[] = this.displayedColumnsViewArray): void {
    this.displayedColumns = columns.map((column: { key: string; value: string }) => column.key);
  }

  /**
   * Sets the default page index.
   *
   * @return {void}
   */
  private setDefaultPageIndex(): void {
    this.pageIndex = this.constantList.DEFAULT_PAGE_INDEX;
  }

  /**
   * Clears the bulk select list.
   *
   * @return {void} This function does not return a value.
   */
  private clearBulkSelectList(): void {
    this.bulkSelectList = [];
  }

  /**
   * Calculates the pagination for the current local items.
   *
   * This function sets the `totalElements` property of the class to the length of the `localItems` array.
   *
   * @return {void} This function does not return a value.
   */
  private calculatePagination(): void {
    this.totalElements = this.localItems.length;
  }

  public getFooterValue(index: number): string {
    const footerItem = this.footerData.find((item: any) => item.index === index);
    return footerItem ? footerItem.value : '';
  }

  public shouldShowFooter(index: number): boolean {
    return this.footerData.some((item: any) => item.index === index);
  }

  public calculateTotal = (fn: (item: any) => number): number => {
    return this.localItems?.reduce((acc: number, item: any) => acc + fn(item), 0);
  };
  public formatValue = (value: number) => `${value?.toFixed(2)} CFA`;

  public calculateFooterValue() {
    const totalCollectedAdults =
      this.calculateTotal(item => +item?.collectedAdultTicketAmount) || 0;
    const totalCollectedChildren =
      this.calculateTotal(item => +item?.collectedChildTicketAmount) || 0;
    const totalCashCollected =
      this.calculateTotal(
        item => +item?.collectedAdultTicketAmount + +item?.collectedChildTicketAmount,
      ) || 0;
    const totalCardCollected = this.calculateTotal(item => +item?.tapInOutLogCardAmount) || 0;
    const totalAmountCollected = totalCashCollected + totalCardCollected || 0;

    this.footerData[1].value = this.formatValue(totalCollectedAdults);
    this.footerData[2].value = this.formatValue(totalCollectedChildren);
    this.footerData[3].value = this.formatValue(totalCashCollected);
    this.footerData[4].value = this.formatValue(totalCardCollected);
    this.footerData[5].value = this.formatValue(totalAmountCollected);
    this.emitFooterData.emit(this.footerData);
  }

  hasFiltersOrSearch(): boolean {
    // Check if any key in params starts with 'filter.' or contains 'search'
    return Object.keys(this.params).some(key =>
      key.startsWith('filter.') || key === 'search'
    );
  }

}
