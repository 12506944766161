import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';


export  const contentLimitValidator = (maxLength: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null; // No content, no error

    // Parse the HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = control.value;

    // Remove images to count only text
    const images = tempDiv.getElementsByTagName('img');
    for (let i = images.length - 1; i >= 0; i--) {
      images[i].remove();
    }

    const textContent = tempDiv.innerText || '';
    return textContent.length > maxLength
      ? { maxlength: { requiredLength: maxLength, actualLength: textContent.length } }
      : null;
  };
};