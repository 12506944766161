<app-table
  [linkText]="'GENERAL.BUTTONS.NEW' | translate"
  linkIcon="plus-square"
  [link]="isEditPermission ? routeList.ADD_PATH : ''"
  [paramFilters]="paramFilters"
  [endPointConfiguration]="endPointConfiguration"
  [displayedColumnsViewArray]="displayedColumnsViewArray"
  [enableTableAction]="false"
  [canUpdateStatus]="true"
  notFoundMessage="MSGS.GENERAL.CLICK_ON_NEW_TO_CREATE_YOUR_FIRST_ENTRY"
  (tableAction)="tableAction($event)"
  (elementClick)="onElementClick($event)">
</app-table>

<app-confirm-dialog
  [confirmText]="'GENERAL.BUTTONS.DELETE' | translate"
  [closeText]="'GENERAL.BUTTONS.CLOSE' | translate"
  message="{{ 'MSGS.ARTICLES.DELETE_ARTICLE' | translate }}"
  extraIcon="icon-trash-filled"
  [eventType]="confirmEventAction"
  (closeDialog)="processModalClose($event)"></app-confirm-dialog>
