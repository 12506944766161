import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseComponent } from '@src/app/core/base';
import { takeUntil } from 'rxjs';
import { USER_STATUS } from '../../core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModule } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.module';
import { UserActivitiesComponent } from '../user-activities/user-activities.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserAddressComponent } from './user-address/user-address.component';
import { UserDocumentsComponent } from './user-documents/user-documents.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
import { ViewPermissionDirectiveModule } from '@src/app/core/shared/directives/view-permission/view-permission.directive.module';
import { UserManagementService } from '@src/app/features/user-management/core';
import { USER_MANAGEMENT_BULK_DELETE, USER_MANAGEMENT_STATUS_UPDATE } from '../../core';
import { ApiResponseInterface } from '@src/app/core/interfaces';
import { UserRepository } from '@src/app/features/user-management/core/repositories/user.repository';
@Component({
  selector: 'app-user-about',
  templateUrl: './user-about.component.html',
  styleUrls: ['./user-about.component.scss'],
  preserveWhitespaces: true,
  standalone: true,
  imports: [
    ...COMMON_MODULES,
    NgbNavModule,
    NgSelectModule,
    ConfirmDialogModule,
    NgbDropdownModule,
    UserActivitiesComponent,
    UserDetailsComponent,
    UserAddressComponent,
    UserDocumentsComponent,
    UserInfoComponent,
    ViewPermissionDirectiveModule,
  ],
})
export class UserAboutComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;

  public dialogMessage: any = '';
  public extraIcon: any = '';
  public iconClass: any = 'text-primary'
  public dialogConfirmText: any = '';
  public status = USER_STATUS;
  public confirmEventAction: 'active' | 'delete' | string = '';
  public user_Type: string = 'admin_user';

  /**
   * Component constructor
   * @param injector injects required services
   * @param pageSettingsService service for managing page settings in BaseComponent
   */
  constructor(
    injector: Injector,
    private pageSettingsService: PageSettingsService,
    private userManagementService: UserManagementService,
    private userRepository: UserRepository,
  ) {
    super(injector);
    // If the user is not in 'edit' mode, set the page mode to 'add'
    if (this.isEditMode() == null) this.pageMode = 'add';
    // Get the user from the resolver
    this.getUser();
    // Define the new page settings
    const newSettings = {
      link1: {
        /**
         * Title for the top breadcrumb
         */
        title: 'BREADCRUMBS.USER_MANAGEMENT.TITLE',
        /**
         * Name for the link in the top breadcrumb
         */
        name:
          this.user_Type === 'admin_user'
            ? 'MENU.ADMIN_USERS'
            : this.user_Type === 'app_user'
              ? 'MENU.APP_USERS'
              : '',
        /**
         * Link for the top breadcrumb
         */
        link:
          this.user_Type === 'admin_user'
            ? this.routeList.ADMIN_USER_LISTING
            : this.routeList.APP_USER_LISTING,
      },
      link2: {
        /**
         * Title for the second breadcrumb
         */
        title:
          this.pageMode === 'add'
            ? 'BREADCRUMBS.USER_MANAGEMENT.ADD_USER'
            : 'BREADCRUMBS.USER_MANAGEMENT.EDIT_USER',
      },
    };

    // Use the service to update the pageSettings in BaseComponent
    this.pageSettingsService.updatePageSettings(newSettings);
  }

  /**
   * Initializes the component and performs any necessary setup operations.
   *
   * This function is called when the component is first created and is used to
   * retrieve any necessary data from the URL parameters, such as the email and
   * token, and perform any initial setup tasks.
   *
   * @return {void} This function does not return a value.
   */
  ngOnInit(): void {
    //Take params from Url ,check for email and token
  }

  /**
   * Subscribes to the data observable from the ActivatedRoute and
   * sets the baseModel property with the data from the observable.
   */
  getUser() {
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        if (data?.user) {
          /**
           * The user data from the ActivatedRoute data observable.
           */
          this.baseModel = data?.user;
          if (this.pageMode === 'edit') {
            this.baseModel?.roles?.forEach((element: any) => {
              if (element?.code && this.constantList.SPECIFIC_ROLES.includes(element.code)) {
                this.user_Type = 'app_user';
              }
            });
          }
        }
      });
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params['type']) {
        this.user_Type = params['type'];
      }
    });
  }

  getClass() {
    const classes = [];
    if (this.baseModel?.status === 'active') {
      classes.push('active');
    }
    if (this.baseModel?.status === 'unverified') {
      classes.push('hide-dropdown-arrow');
    } else {
      classes.push('dropdown-toggle');
    }
    classes.push(this.status[this.baseModel?.status]?.toLowerCase());
    return classes;
  }

  /**
   * Checks if the current user has the super admin role.
   * @returns true if the current user has the super admin role, false otherwise
   */
  isSuperAdmin(): boolean {
    // Checks if the current user has the super admin role by checking if the user's
    // roles array contains any objects with a code property equal to 'super_admin'.
    return this.baseModel?.roles?.some((role: any) => role?.code === 'super_admin');
  }

  /**
   * Opens the confirmation dialog to delete the user
   */
  deleteUser() {
    if (this.confirmDialCompRef) {
      // Set the event action for the confirmation dialog
      this.confirmEventAction = 'delete';
      // Set the message to display in the confirmation dialog
      this.dialogMessage = this.translate.instant('MSGS.USERS.DELETE_USER');
      this.extraIcon = 'icon-trash-filled';
      this.iconClass = 'text-primary'
      // Set the text for the 'confirm' button in the confirmation dialog
      this.dialogConfirmText = this.translate.instant('GENERAL.BUTTONS.DELETE');
      // Open the confirmation dialog with the user data
      this.confirmDialCompRef.openDialog(this.baseModel);
    }
  }

  /**
   * Processes the modal close event
   *
   * @param event the event data containing the action and event type
   */
  public processModalClose(event: {
    data: any;
    eventType: string | null;
    confirmed: boolean;
    action: 'confirm' | 'dismiss';
  }) {
    if (event.action == 'confirm') {
      // If event type is delete
      if (event.eventType == 'delete') {
        this.userRepository
          // Call API to delete user
          .delete(String(event.data?.id))
          .pipe(this.destroy$())
          .subscribe({
            // Successful API response
            next: (response: unknown) => {
              const res = response as ApiResponseInterface<any>;

              if (res.status === this.constantList.SUCCESS_STATUS) {
                this.userManagementService.isDataModified = false;
                this.sharedDataService.dataModified.next({
                  component: 'user-about',
                  isModified: this.userManagementService.isDataModified,
                  data: {},
                });
                // Navigate to user listing page
                if (this.user_Type === 'admin_user') {
                  this.helperService.goToRoute(this.routeList.ADMIN_USER_LISTING);
                } else {
                  this.helperService.goToRoute(this.routeList.APP_USER_LISTING);
                }
                // Show success message
                this.httpService.showMessage(
                  this.translate.instant('MSGS.USERS.DELETE_USER_SUCCESS'),
                  'success',
                );
              } else {
                // Show error message
                this.httpService.showMessage(
                  this.translate.instant('MSGS.USERS.UNABLE_TO_DELETE'),
                  'error',
                );
              }
            },
          });
      } else {
        this.userRepository
          .update(event.data?.id, {
            status: event?.eventType,
          })
          .pipe(this.destroy$())
          .subscribe({
            // Successful API response
            next: (response: unknown) => {
              const res = response as ApiResponseInterface<any>;
              if (res.status === this.constantList.SUCCESS_STATUS) {
                // Update the user status
                this.baseModel.status = event?.eventType;
                if (event?.eventType === 'inactive') {
                  this.httpService.showMessage(
                    this.translate.instant('MSGS.USERS.UPDATE_USER_STATUS_DEACTIVATED'),
                    'success',
                  );
                } else {
                  this.httpService.showMessage(
                    this.translate.instant('MSGS.USERS.UPDATE_USER_STATUS'),
                    'success',
                  );
                }
              } else {
                // Show error message
                this.httpService.showMessage(
                  this.translate.instant('MSGS.GENERAL.STATUS_UPDATED_SUCCESS'),
                  'error',
                );
              }
            },
          });
      }
    }
  }

  /**
   * Update the user status
   *
   * @param status the new status of the user
   * @param id the user id
   */
  public updateUserStatus(status: string, id: string) {
    // Log the status and the base model
    // Check if the status is different from the current user's status
    if (status !== this.baseModel.status) {
      // If the confirm dialog component is defined, open the dialog
      if (this.confirmDialCompRef) {
        // Update the confirm event action
        this.confirmEventAction = status;
        // Set the dialog message
        if(this.baseModel.status === 'active'){
          this.dialogMessage = this.translate.instant('MSGS.USERS.ARE_YOU_SURE_DEACTIVATE');
        }
        else{
          this.dialogMessage = this.translate.instant('MSGS.USERS.ARE_YOU_SURE_ACTIVATE');
        }
        this.extraIcon = 'icon-warning-2';
        this.iconClass = 'text-danger'
        // Set the dialog confirm button text
        this.dialogConfirmText = this.translate.instant('GENERAL.BUTTONS.CONFIRM');
        // Open the dialog
        this.confirmDialCompRef.openDialog(this.baseModel);
      }
    }
  }
}
