import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseComponent } from '@src/app/core/base';
import { DEFAULT_DEBOUNCE_TIME } from '@src/app/core/constants';
import { COMMON_MODULES } from '@src/app/core/constants/common-module.constant';
import { ApiResponseInterface, ComponentCanDeactivate } from '@src/app/core/interfaces';
import { PageSettingsService } from '@src/app/core/services/pageSetting.service';
import { ConfirmDialogComponent } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModule } from '@src/app/core/shared/components/confirm-dialog/confirm-dialog.module';
import { ViewPermissionDirectiveModule } from '@src/app/core/shared/directives/view-permission/view-permission.directive.module';
import { USER_STATUS } from '@src/app/features/user-management/core';
import { UserRepository } from '@src/app/features/user-management/core/repositories/user.repository';
import { UserManagementService } from '@src/app/features/user-management/core/services/user-management.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { AppStatus } from "../../../../core/shared/components/app-status/app-status.component";
import { SubCategoryUpdateRequest } from '../../core/classes/sub-category-update-request';
import { SUB_CATEPGORY_BY_ID_BASE_URL } from '../../core/constants/apis_list';
import { SubCategoriesRepository } from '../../core/repositories/sub-categories.repository';
import { SubCategoryInfoComponent } from '../sub-category-info/sub-category-info.component';
@Component({
  selector: 'app-create-sub-category',
  standalone: true,
  imports: [
    ...COMMON_MODULES,
    NgbNavModule,
    NgSelectModule,
    ConfirmDialogModule,
    NgbDropdownModule,
    SubCategoryInfoComponent,
    ViewPermissionDirectiveModule,
    AppStatus
  ],
  templateUrl: './create-sub-category.component.html',
  styleUrl: './create-sub-category.component.scss'
})
export class CreateSubCategoryComponent extends BaseComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild(ConfirmDialogComponent, { static: false }) confirmDialCompRef!: ConfirmDialogComponent;

  public active: number = 1;

  public dialogMessage: any = '';
  public dialogConfirmText: any = '';
  public status = USER_STATUS;
  public confirmEventAction: 'active' | 'delete' | string = '';
  public user_Type: string = 'admin_user';
  public isLoading: boolean = false;

  public column: any = {
    key: 'status',
    value: 'COMPONENTS.CATEGORIES.STATUS',
    headingClass: 'ps-4',
    type: 'status',
    sortable: false,
    defaultTitle: true,
    options: [
      {
        label: 'GENERAL.BUTTONS.ACTIVATE',
        value: 'active',
        icon: 'tick-circle',
      },
      {
        label: 'GENERAL.BUTTONS.DEACTIVATE',
        value: 'inactive',
        icon: 'close-circle',
      },
    ],
    endpoint: SUB_CATEPGORY_BY_ID_BASE_URL,
    success: this.translate.instant('MSGS.SUB_CATEGORIES.UPDATE_SUB_CATEGORY_STATUS'),
    successSecond: this.translate.instant('MSGS.SUB_CATEGORIES.UPDATE_SUB_CATEGORY_STATUS_DEACTIVATED'),
    alert: this.translate.instant('MSGS.SUB_CATEGORIES.ARE_YOU_SURE_DEACTIVATE'),
    alertSecond: this.translate.instant('MSGS.SUB_CATEGORIES.ARE_YOU_SURE_ACTIVATE'),
    error: this.translate.instant('MSGS.SUB_CATEGORIES.UNABLE_TO_UPDATE_SUBCATEGORY_STATUS'),
  };



  /**
   * Constructor for the CreateSubCategoryComponent.
   *
   * @param {Injector} injector - The injector instance.
   * @param {UserManagementService} userManagementService - The UserManagementService instance.
   */

  constructor(
    injector: Injector,
    private pageSettingsService: PageSettingsService,
    private userManagementService: UserManagementService,
    private userRepository: UserRepository,
    private subCategoriesRepository: SubCategoriesRepository
  ) {
    super(injector);
    // If the user is not in 'edit' mode, set the page mode to 'add'
    if (this.isEditMode() == null) this.pageMode = 'add';

    const newSettings = {
      link1: {
        /**
         * Title for the top breadcrumb
         */
        title: 'BREADCRUMBS.PRODUCTS_MANAGEMENT.TITLE',
        /**
         * Name for the link in the top breadcrumb
         */
        name: 'MENU.SUB_CATEGORIES',
        /**
         * Link for the top breadcrumb
         */
        link: this.routeList.APP_SUB_CATEGORY
      },
      link2: {
        /**
         * Title for the second breadcrumb
         */
        title:
          this.pageMode === 'add'
            ? 'BREADCRUMBS.SUB_CATEGORY.ADD_SUB_CATEGORY'
            : 'BREADCRUMBS.SUB_CATEGORY.EDIT_SUB_CATEGORY',
      },
    };
    // Use the service to update the pageSettings in BaseComponent
    this.pageSettingsService.updatePageSettings(newSettings);

    this.getSubCategories();
  }

  getSubCategories() {
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        if (data?.subCategory) {
          /**
           * The store data from the ActivatedRoute data observable.
           */
          this.baseModel = data?.subCategory;

        }
      });
  }

  /**
   * Checks if the user data has been modified and returns a Promise<boolean> or boolean indicating whether the user can be deactivated.
   *
   * @return {Promise<boolean> | boolean} A Promise<boolean> or boolean indicating whether the user can be deactivated.
   */
  canDeactivate(): Promise<boolean> | boolean {
    return this.userManagementService.isDataModified;
  }

  /**
   * Component initialize method
   */
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['type']) {
        this.user_Type = params['type'];
      }
    });
    // Subscribe to route data and get user data
    this.dataSubscription$ = this.activatedRoute.data
      .pipe(takeUntil(this._unsubscribeToastrMessage$))
      .subscribe((data: any) => {
        if (data?.subCategory) {
          this.baseModel = this.helperService.clone(data?.subCategory);
          this.baseModel.parentCategoryId = this.baseModel.parentCategory.id;
        }
      });
  }

  /**
   * Checks if the current user has the super admin role.
   * @returns true if the current user has the super admin role, false otherwise
   */
  isSuperAdmin(): boolean {
    // Checks if the current user has the super admin role by checking if the user's
    // roles array contains any objects with a code property equal to 'super_admin'.
    return this.baseModel?.roles?.some((role: any) => role?.code === 'super_admin');
  }

  /**
   * Opens the confirmation dialog to delete the user
   */
  deleteUser() {
    if (this.confirmDialCompRef) {
      // Set the event action for the confirmation dialog
      this.confirmEventAction = 'delete';
      // Set the message to display in the confirmation dialog
      this.dialogMessage = this.translate.instant('MSGS.USERS.DELETE_USER');
      // Set the text for the 'confirm' button in the confirmation dialog
      this.dialogConfirmText = this.translate.instant('GENERAL.BUTTONS.DELETE');
      // Open the confirmation dialog with the user data
      this.confirmDialCompRef.openDialog(this.baseModel);
    }
  }

  /**
   * Processes the modal close event
   *
   * @param event the event data containing the action and event type
   */
  public processModalClose(event: {
    data: any;
    eventType: string | null;
    confirmed: boolean;
    action: 'confirm' | 'dismiss';
  }) {
    if (event.action == 'confirm') {
      // If event type is delete
      if (event.eventType == 'delete') {
        this.userRepository
          // Call API to delete user
          .delete(String(event.data?.id))
          .pipe(this.destroy$())
          .subscribe({
            // Successful API response
            next: (response: unknown) => {
              const res = response as ApiResponseInterface<any>;

              if (res.status === this.constantList.SUCCESS_STATUS) {
                this.userManagementService.isDataModified = false;
                this.sharedDataService.dataModified.next({
                  component: 'user-about',
                  isModified: this.userManagementService.isDataModified,
                  data: {},
                });
                // Navigate to user listing page
                if (this.user_Type === 'admin_user') {
                  this.helperService.goToRoute(this.routeList.APP_SUB_CATEGORY);
                } else {
                  this.helperService.goToRoute(this.routeList.APP_SUB_CATEGORY);
                }
                // Show success message
                this.httpService.showMessage(
                  this.translate.instant('MSGS.USERS.DELETE_USER_SUCCESS'),
                  'success',
                );
              } else {
                // Show error message
                this.httpService.showMessage(
                  this.translate.instant('MSGS.USERS.UNABLE_TO_DELETE'),
                  'error',
                );
              }
            },
          });
      } else {
        this.userRepository
          .update(event.data?.id, {
            status: event?.eventType,
          })
          .pipe(this.destroy$())
          .subscribe({
            // Successful API response
            next: (response: unknown) => {
              const res = response as ApiResponseInterface<any>;
              if (res.status === this.constantList.SUCCESS_STATUS) {
                // Update the user status
                this.baseModel.status = event?.eventType;
                if (event?.eventType === 'inactive') {
                  this.httpService.showMessage(
                    this.translate.instant('MSGS.USERS.UPDATE_USER_STATUS_DEACTIVATED'),
                    'success',
                  );
                } else {
                  this.httpService.showMessage(
                    this.translate.instant('MSGS.USERS.UPDATE_USER_STATUS'),
                    'success',
                  );
                }
              } else {
                // Show error message
                this.httpService.showMessage(
                  this.translate.instant('MSGS.GENERAL.STATUS_UPDATED_SUCCESS'),
                  'error',
                );
              }
            },
          });
      }
    }
  }

  /**
   * Update the user status
   *
   * @param status the new status of the user
   * @param id the user id
   */

  public updateStatus(status: string) {
    if (status == "inactive")
      this.baseModel.isActivated = false
    else {
      this.baseModel.isActivated = true
    }
    let apiContainer = this.subCategoriesRepository.update(this.baseModel.id, this.parseRequestModel(this.baseModel));

    apiContainer
      .pipe(this.destroy$(), distinctUntilChanged(), debounceTime(DEFAULT_DEBOUNCE_TIME))
      .subscribe({
        /**
         * Executes the next callback function asynchronously. If the response status is SUCCESS_STATUS or SUCCESS_STATUS_CODE,
         * it updates the calendar and shows error messages if any. Otherwise, it shows success message.
         *
         * @param {any} res - The response object.
         * @return {Promise<void>} A promise that resolves when the function completes.
         */
        next: async (res: any) => {
          if (res.status === this.constantList.SUCCESS_STATUS || res.status === this.constantList.SUCCESS_STATUS_CODE) {
            this.httpService.showMessage(
              this.translate.instant('MSGS.GENERAL.STATUS_UPDATED_SUCCESS'),
              'success',
            );
            this.baseModel.isActivated = res.body?.isActivated;

          }
          // this.router.navigate(['/subcategories']);
        },
      });
  }

  private parseRequestModel(baseModel: any): SubCategoryUpdateRequest {
    return new SubCategoryUpdateRequest(
      baseModel.name,
      baseModel.description,
      baseModel.order,
      baseModel.isActivated,
      baseModel.parentCategoryId
    );
  }

}
