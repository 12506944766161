import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { JobSignedUrlRequest, SignedUrlResponse, DeleteResourceParams } from '../models/job-signed-url.model';
import { JOB_SIGNED_URLS } from '@src/app/features/item-management/core';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetches signed URLs for file uploads.
   * @param payload - The request payload containing resource and file names.
   * @returns An Observable resolving to an array of SignedUrlResponse.
   */
  getSignedUrls(payload: JobSignedUrlRequest): Observable<SignedUrlResponse[]> {
    return this.httpClient.post<SignedUrlResponse[]>(JOB_SIGNED_URLS, payload).pipe(
      catchError(error => {
        console.error('Error fetching signed URLs:', error);
        return throwError(() => error);
      }),
    );
  }

  /**
   * Deletes a resource using its ID, resource name, and file name.
   * @param params - Object containing the resource ID, resource name, and file name.
   * @returns An Observable resolving to the server's response upon successful deletion.
   */
  deleteResource(params: DeleteResourceParams): Observable<any> {
    const httpParams = new HttpParams()
      .set('id', params.id)
      .set('resourceName', params.resourceName)
      .set('fileName', params.fileName);

    return this.httpClient.delete<any>(JOB_SIGNED_URLS, { params: httpParams }).pipe(
      catchError(error => {
        console.error('Error deleting resource:', error);
        return throwError(() => error);
      }),
    );
  }

  /**
   * Uploads a single file to the specified signed URL.
   * @param signedUrl - The signed URL to upload to.
   * @param file - The file to upload.
   * @returns An Observable resolving when the upload is successful.
   */
  uploadToSignedUrl(signedUrl: string, file: File): Observable<void> {
    const contentType = file.type || 'application/octet-stream';

    return this.httpClient
      .put(signedUrl, file, { headers: { 'Content-Type': contentType }, observe: 'response' })
      .pipe(
        map(() => {
          console.log(`Successfully uploaded file`);
        }),
        catchError(error => {
          console.error(`Error uploading file ${file.name} to ${signedUrl}:`, error);
          return throwError(() => error);
        }),
      );
  }

  /**
   * Uploads multiple files using signed URLs.
   * @param resource - The resource type.
   * @param id - The unique ID for the resource.
   * @param fileNames - Array of file names to upload.
   * @param files - Array of files to upload.
   * @param fields - Array of fields to associate with each file (e.g., 'profilePic', 'cv', 'portfolio-0').
   * @returns An Observable resolving to an array of updated file names returned by the server.
   */
  uploadFilesToResource(
    resource: string,
    id: string,
    fileNames: string[],
    files: File[],
    fields: string[] // New parameter to track the field for each file
  ): Observable<[]> {
    if (!fileNames.length || !files.length) {
      return of([]); // Return an empty array if there are no files to upload
    }

    // Prepare payload for fetching signed URLs
    const payload: JobSignedUrlRequest = {
      id,
      resourceName: resource,
      images: fileNames,
    };

    return this.getSignedUrls(payload).pipe(
      switchMap((signedUrls: any) => {
        if (signedUrls.length !== files.length) {
          throw new Error('Mismatch between signed URLs and files.');
        }
  
        // Map each file to its corresponding signed URL and upload it
        const uploadTasks = signedUrls.map((signedUrlResponse: any, index: any) => {
          const { signedUrl, updatedFileName } = signedUrlResponse;
          const fileToUpload = files[index];
          const field = fields[index]; // Get the field associated with this file
          console.log('File uploaded successfully:', { field, updatedFileName });

          if (!fileToUpload) {
            console.warn(`No file found for signed URL: ${signedUrl}`);
            return of(null); // Skip if no file found for signed URL
          }
  
          return this.uploadToSignedUrl(signedUrl, fileToUpload).pipe(
            tap(() => {
              console.log('File uploaded successfully:', { field, updatedFileName });
            }),
            map(() => {
              if (!updatedFileName) {
                throw new Error('Updated file name is undefined.');
              }
              return { field, fileName: updatedFileName };
            }),
            catchError(error => {
              console.error('Caught error during file upload:', error);
          
              if (!error || typeof error !== 'object') {
                console.error('Unexpected error structure:', error);
                return of(null); // Gracefully handle unknown errors
              }
          
              console.error('Error message:', error.message || 'Unknown error');
              return of(null); // Continue without failing the entire pipeline
            }))
        });
  
        // Execute all upload tasks in parallel
        return forkJoin(uploadTasks).pipe(
          map((results: any) => results.filter((result: any) => result !== null)) // Filter out any failed uploads
        );
      }),
      catchError(error => {
        console.error('Error during file upload process:', error);
        return throwError(() => error); // Propagate error for further handling
      })
    );
  }
}
